import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FaCamera, FaRegHeart } from "react-icons/fa";
import { TbMessageQuestion } from "react-icons/tb";
import { FaWhatsapp } from "react-icons/fa";

import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import { Link, useParams } from "react-router-dom";
import gallery1 from "../../assets/media/gallery/gallery1.jpg";
import gallery2 from "../../assets/media/gallery/gallery2.jpg";
import gallery3 from "../../assets/media/gallery/gallery3.jpg";
import gallery4 from "../../assets/media/gallery/gallery4.jpg";
import gallery5 from "../../assets/media/gallery/gallery5.jpg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import StoreLocater from "../../components/StoreLocator/StoreLocater";
import { listCategory } from "../../functions/ProductDetails/Category";
import {
  FilterProductByWeight,
  listCategoryProducts,
} from "../../functions/ProductDetails/ProductCategory";

import { IoIosHeart } from "react-icons/io";
import TryCardModel from "../../components/TryOn/TryOnCardModel";
import {
  AddToWhislist,
  GetWhishlistUser,
  RemoveFromWhislist,
} from "../../functions/Prospect/Prsopect";
import { getTryJewelByProduct } from "../../functions/TryOn/TryJewel";
import LoginModal from "../modal/loginModal";
import { Helmet } from "react-helmet";
import TryonNew from "../../components/TryOn/TryonNew";
import TryonNew2 from "../../components/TryOn/TryonNew2";
const ProductDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [lisCateg, setLisCateg] = useState([]);
  const [lisCategProd, setLisCategProd] = useState([]);
  const [lisCategActiveItemID, setLisCategActiveItemID] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Amore"); // Initialize it with a default value
  const [selectCategActive, setselectCategActive] = useState(""); // Initialize it with a default value
  const [descr, setDescrip] = useState(""); // Initialize it with a default value
  const [descrActi, setDescripActiv] = useState(""); // Initialize it with a default value
  const [activeCheck, setActiveCheck] = useState(false);
  const [LoadCheck, setLoadCheck] = useState(false);

  useEffect(() => {
    setLoadCheck(true);
  }, []);

  const { category } = useParams();

  useEffect(() => {
    // loadCatgeories();
    listCategory().then((res) => {
      setActiveCheck(false);
      setselectCategActive(category);
      const foundCategory = res.data.find((c) => c._id == category);
      setSelectedCategory(foundCategory.Category);
      setDescrip(foundCategory.Description);
      // setLisCategActiveItem(foundCategory.Category);
      setLisCategActiveItemID(foundCategory._id);
    });
  }, [category]);

  const [show, setShow] = useState(false);

  const [imagePro, setImage] = useState(null);
  const [ProCheck, setProCheck] = useState(null);

  const handleShow = (PRODUCID) => {
    setShow(true);
    const pro = lisCategProd.find((product) => product._id === PRODUCID);
    console.log("pro", pro);
    setProCheck(PRODUCID);
    localStorage.setItem("ProductImage", pro.ProductImage);
    setImage(pro.ProductImage);
  };

  const handleClose = () => {
    setShow(false);
    console.log("close");
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // CUSTOMERSIGNUP

  const [modalList, setModalList] = useState(false);

  const [selectedLayoutId, setSelectedLayoutId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [WishlistCheck, setWishListCheck] = useState(false);
  const [WishlistList, setWishlistList] = useState([]);
  const [UserCheck, setUserCheck] = useState(false);

  // WEIGHT FILTER
  const [weightRange, setWeightRange] = useState([0, 100]);

  const [isTry, setIsTry] = useState(false);
  const [tryProduct, setTryProduct] = useState("");
  const [tryJewel, setTryJewel] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    const userid = localStorage.getItem("RegisterUserID");

    GetWhishlistUser(userid)
      .then((res) => {
        setWishlistList(res.data);
      })
      .catch((err) => {
        console.log("error in getting whishlist");
      });
    // Fetch products based on the weight range
  }, []);

  useEffect(() => {
    loadTryJewel();
  }, [tryProduct]);

  const loadTryJewel = () => {
    getTryJewelByProduct(tryProduct._id).then((res) => {
      setTryJewel(res.data);
    });
  };

  const [wishlistMessage, setWishlistMessage] = useState("");
  const [removeWishList, setRemoveWishList] = useState("");

  const tog_list_Camera = (product) => {
    const userid = localStorage.getItem("RegisterUserID");
    if (!userid) {
      setModalList(true);
      setIsSubmit(false);
      setUserCheck(false);
    } else {
      // setTryProduct(product);
      setTryProduct(product._id);
      getTryJewelByProduct(product._id).then((res) => {
        if (!res.data) {
          // setDisabledButton(true);
        } else {
          setTryJewel(res.data);
          setIsTry(!isTry);
        }
      });
    }
  };

  const tog_list = (PID) => {
    let productid = PID;
    localStorage.setItem("ProductID", PID);
    const userid = localStorage.getItem("RegisterUserID");

    if (userid) {
      if (WishlistList.length === 0 || !WishlistList.includes(PID)) {
        AddToWhislist({ userid, productid })
          .then((res) => {
            setWishlistList(res.data);
            setWishListCheck(true);
            setWishlistMessage("Product added to wishlist!");

            setTimeout(() => {
              setWishlistMessage("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina adding list", err);
          });
      } else {
        RemoveFromWhislist({ userid, productid })
          .then((res) => {
            setWishlistList(res.data);
            setWishListCheck(false);
            setRemoveWishList("Product removed from wishlist!");
            setTimeout(() => {
              setRemoveWishList("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina remove list", err);
          });
      }
    } else {
      // setModalList(!modalList);
      setModalList(true);
      setIsSubmit(false);
      setUserCheck(true);
    }

    setSelectedLayoutId(PID); // Assuming you have a state to store the selected layoutId
  };

  useEffect(() => {
    if (UserCheck) {
      setModalList(true);
    }
  }, [UserCheck]);

  const showSlide = (slideNumber) => {
    setCurrentSlide(slideNumber);
  };
  const nextSlide = () => {
    setCurrentSlide((currentSlide % 5) + 1);
  };

  useEffect(() => {
    loadCatgeories();
    loadCatgeoryproducts();
    setActiveCheck(true);
  }, []);

  const loadCatgeories = () => {
    listCategory().then((res) => {
      setLisCateg(res.data);
    });
  };

  const loadCatgeoryproducts = () => {
    listCategoryProducts().then((res) => {
      setLisCategProd(res.data);
    });
  };

  const handleChangeWeight = (e) => {
    setWeightRange([0, parseInt(e.target.value)]);
    FilterProductByWeight(weightRange[0], weightRange[1])
      .then((res) => {
        console.log("res in filter", res.data);
      })
      .catch((err) => {
        console.log("error in filter", err);
      });
  };

  const handleCategorySelection = (category) => {
    setActiveCheck(false);
    setselectCategActive(category);
    const foundCategory = lisCateg.find((c) => c._id == category);
    setSelectedCategory(foundCategory.Category);
    setDescrip(foundCategory.Description);
    setLisCategActiveItemID(foundCategory._id);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 1 ? 5 : currentSlide - 1);
  };

  const galleryImages = [gallery1, gallery2, gallery3, gallery4, gallery5];
  return (
    <React.Fragment>
      <Helmet>
        <title>
          Shop Diamond and Gold Mangalsutra Designs|Ziya Mangalsutra
        </title>
        <meta
          name="description"
          content="Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love."
        />
        <link
          rel="canonical"
          href="https://ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3"
        />

        <meta
          name="keywords"
          content="mangalsutra Design, diamond mangalsutra design, Gold mangalsutra, traditional mangalsutra, modern design mangalsutra"
        />
        {/* <!----======= Seo Meta  =======----> */}
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="author" content="" />
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love."
        />
        <meta
          name="og:title"
          content="Shop Diamond and Gold Mangalsutra Designs | Ziya Mangalsutra"
        />
        <meta
          name="og:description"
          content="Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love."
        />
        <meta
          name="og:image"
          content="https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png"
        />
        <meta
          name="og:url"
          content="https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3"
        />
        <meta name="og:site_name" content="Ziya Mangalsutra" />
        <meta name="og:locale" content="en_IN" />
        <meta name="og:video" content="" />
        <meta name="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Shop Diamond and Gold Mangalsutra Designs | Ziya Mangalsutra"
        />
        <meta
          name="twitter:description"
          content="Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love."
        />
        <meta
          name="twitter:image"
          content="https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png"
        />
        <meta name="twitter:site" content="@ziyamangalsutra" />
        <meta name="twitter:creator" content="@ziyamangalsutra" />
        <script
          type="application/ld+json"
          class="yoast-schema-graph yoast-schema-graph--main"
        >
          {`
        {
        "@context":"https://schema.org",
        "@graph":[ {
        "@type":"WebSite",
        "@id":" https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3#webpage",
        "url":" https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3",
        "name":"Ziya Mangalsutra",
        "description":"Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love."
		}
        ,
        {
        "@type":"WebPage",
        "@id":"https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3#webpage",
        "url":"https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3",
        "inLanguage":"en-US",
        "name":"Ziya Mangalsutra",
        "isPartOf": {
        "@id": "https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3"
        }
        ,
        "datePublished":"2017-09-20T09:19:08+00:00",
        "dateModified":"2020-11-25T06:40:11+00:00",
        "description":"Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love."
        }
        ]
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "product",
        "name": "Ziya Mangalsutra ",
        "image": "https://server.ziyamangalsutra.com/uploads/CategoryProducts/ProductImage_1703573683401_zd1.jpg",
        "sku": "IF",
        "mpn": "IF",
        "description": "Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love.",
        "aggregateRating": {"@type": "aggregateRating", "ratingValue": "5", "reviewCount": "1", "bestRating":"5 " }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Mr Chetan Thadeshwar",
        "url": "https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3",
        "image": "https://server.ziyamangalsutra.com/uploads/CategoryProducts/ProductImage_1703573683401_zd1.jpg",
        "jobTitle": "Proprietor",
        "worksFor": {
        "@type": "Organization",
        "name": "Ziya Mangalsutra"
        }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "Ziya Mangalsutra",
        "url": "https://https://www.ziyamangalsutra.com/product-details/65360840af0c97d97d5428c3",
        "logo": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "image": "https://server.ziyamangalsutra.com/uploads/CategoryProducts/ProductImage_1703573683401_zd1.jpg",
        "description": "Discover timeless elegance with our collection of diamond and gold mangalsutras at Ziya Mangalsutra. Shop online for the perfect symbol of your love.",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "Cotton Exchange Building, B-1, Jewel World, Kalbadevi Rd, opp. Surati hotel, Mumbai, Maharashtra - 400002",
        "addressLocality": "Surati hotel",
        "addressRegion": "Mumbai",
        "addressCountry": "India"
        },
        "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+022 43111222"
        }
        }
        `}
        </script>
      </Helmet>
      <Header />

      <div id="site-main" className="site-main">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div
              id="title"
              className="page-title page-title-dark title-product"
            >
              <div className="section-container text-start">
                <div className="content-title-heading">
                  <h1 className="text-title-heading">{selectedCategory}</h1>
                </div>
                <div className="breadcrumbs">
                  <Link to="/">Home</Link>
                  <span className="delimiter" />
                  <Link to="/the-collection">The Collection</Link>
                  <span className="delimiter" />
                  {selectedCategory}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="content" className="site-content" role="main">
                <div className="section-padding">
                  <Container>
                    <Row>
                      <Col className="col-lg-3 col-md-12 col-12 sidebar left-sidebar blog-details-sidebar">
                        {/* Block Post Categories */}

                        <div className="block block-post-tags">
                          <div className="block-title">
                            <h2 className="categroyTitle">Categories</h2>
                          </div>
                          <div className="block-content">
                            <div className="post-tags-list">
                              <ul className="categoryUl">
                                {lisCateg.map((l, index) =>
                                  l.isActive &&
                                  l.IsPublic &&
                                  !l.IsTopProducts ? (
                                    <React.Fragment key={l._id}>
                                      <li
                                        value={l._id}
                                        onClick={() =>
                                          handleCategorySelection(l._id)
                                        }
                                        className={
                                          l._id === lisCategActiveItemID
                                            ? "activeCategory"
                                            : ""
                                        }
                                      >
                                        <button
                                          className="btn btn-lg product-button"
                                          style={{
                                            background: "none",
                                            border: "none",
                                            padding: "7px",
                                            font: "inherit",
                                            width: "100%",
                                            textAlign: "start",
                                            textDecoration: "none",
                                            color:
                                              l._id === lisCategActiveItemID &&
                                              "white",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {l.Category}
                                        </button>
                                      </li>
                                    </React.Fragment>
                                  ) : null
                                )}
                              </ul>
                            </div>

                            <div className="block-title">
                              <h2 className="categroyTitle">
                                Filter By Weight
                              </h2>
                            </div>
                            <div
                              className="slide"
                              style={{ position: "relative" }}
                            >
                              {/* <WeightProgressBar /> */}
                              <input
                                style={{
                                  width: "90%",
                                  backgroundColor: "#453444",
                                  WebkitAppearance: "none",
                                  // appearance: "none",
                                  height: "8px",
                                  borderRadius: "4px",
                                  background: "#ccc",
                                  outline: "none",
                                  cursor: "pointer",
                                }}
                                type="range"
                                min="0"
                                max="100"
                                value={weightRange[1]}
                                // onChange={(e) =>
                                //   setWeightRange([0, parseInt(e.target.value)])
                                // }
                                onChange={handleChangeWeight}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  top: 15,
                                  left: 40,
                                  left: `${(weightRange[1] / 100) * 100}%`,
                                  transform: "translateX(-50%)",
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <p style={{ fontSize: "15px" }}>
                                  {" "}
                                  {weightRange[1]} g
                                </p>
                              </div>
                              <div
                                style={{
                                  position: "absolute",
                                  top: 15,
                                  // left: 30,
                                  transform: "translateX(-50%)",
                                  width: "auto",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <p style={{ fontSize: "15px" }}>
                                  {weightRange[0]}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col className=" col-lg-9 col-md-12 col-12 md-b-30 blog-details-content">
                        <div className="post-details">
                          <h2 className="productDetailsTitle">
                            {selectedCategory}
                          </h2>
                          <div className="post-content clearfix">
                            {activeCheck ? (
                              <p className="productDesription">{descrActi}</p>
                            ) : (
                              <p className="productDesription">{descr}</p>
                            )}

                            <div>
                              <div className="posts-list grid">
                                <Row>
                                  {activeCheck
                                    ? lisCategProd
                                        .filter(
                                          (product) =>
                                            product.Category ===
                                            lisCategActiveItemID
                                        )
                                        .map(
                                          (product, index) =>
                                            product.isActive &&
                                            product.isPublic &&
                                            !product.IsTopProduct && (
                                              <Col
                                                lg="3"
                                                md="6"
                                                sm="12"
                                                key={index}
                                              >
                                                <div className="post-entry clearfix post-wapper">
                                                  <div className="post-image">
                                                    <a
                                                      href="/customer-signup"
                                                      onClick={() =>
                                                        tog_list(product._id)
                                                      }
                                                    >
                                                      <img
                                                        src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`}
                                                        onClick={() => {
                                                          openModal();
                                                          // handleShow();
                                                          showSlide(index + 1);
                                                        }}
                                                        className="hover-shadow cursor"
                                                        alt={
                                                          product.ProductName
                                                        }
                                                      />
                                                    </a>
                                                  </div>
                                                </div>
                                              </Col>
                                            )
                                        )
                                    : lisCategProd
                                        .filter(
                                          (product) =>
                                            product.Category ===
                                            selectCategActive
                                        )
                                        .filter(
                                          (product) =>
                                            product.ProductWeight >=
                                              weightRange[0] &&
                                            product.ProductWeight <=
                                              weightRange[1]
                                        )
                                        .map(
                                          (product, index) =>
                                            product.isActive &&
                                            product.isPublic &&
                                            !product.IsTopProduct && (
                                              <Col
                                                lg="3"
                                                md="6"
                                                sm="12"
                                                key={index}
                                              >
                                                <div className="post-entry clearfix post-wapper">
                                                  <div className="post-image">
                                                    <Link
                                                      // to={`/product-new/${product._id}/${selectCategActive}`}
                                                      to="#"
                                                    >
                                                      <Card className="hover-shadow  theColectionImg">
                                                        <Link
                                                          to="#"
                                                          style={{
                                                            textAlign: "end",
                                                          }}
                                                          onClick={() =>
                                                            tog_list(
                                                              product._id
                                                            )
                                                          }
                                                        >
                                                          {WishlistList.includes(
                                                            product._id
                                                          ) ? (
                                                            <IoIosHeart
                                                              style={{
                                                                color:
                                                                  "#e41717cf",
                                                                fontSize:
                                                                  "24px",
                                                              }}
                                                            />
                                                          ) : (
                                                            <FaRegHeart
                                                              style={{
                                                                fontSize:
                                                                  "22px",
                                                              }}
                                                            />
                                                          )}
                                                        </Link>

                                                        <img
                                                          src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`}
                                                          onClick={() => {
                                                            handleShow(
                                                              product._id
                                                            );
                                                            openModal();
                                                            showSlide(
                                                              index + 1
                                                            );
                                                          }}
                                                          alt={
                                                            product.ProductName
                                                          }
                                                        />
                                                        <p>
                                                          <b>
                                                            {
                                                              product.ProductWeight
                                                            }
                                                            g
                                                          </b>
                                                        </p>
                                                      </Card>
                                                      {/* <ProductImgModal /> */}
                                                    </Link>

                                                    <div className="tryAtBtn">
                                                      <button
                                                        to="#"
                                                        className="btn button button-primary animation-horizontal"
                                                        onClick={() =>
                                                          tog_list_Camera(
                                                            product
                                                          )
                                                        }
                                                      >
                                                        TRY ON <FaCamera />
                                                      </button>
                                                    </div>

                                                    {wishlistMessage && (
                                                      <div className="wishlist-message-container">
                                                        <div className="wishlist-message">
                                                          <h4
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {wishlistMessage}
                                                          </h4>
                                                        </div>
                                                      </div>
                                                    )}
                                                    {removeWishList && (
                                                      <div className="wishlist-remove-container">
                                                        <div className="wishlist-remove-message">
                                                          <h4
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {removeWishList}
                                                          </h4>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </Col>
                                            )
                                        )}
                                </Row>

                                <Modal
                                  show={show}
                                  onHide={handleClose}
                                  centered
                                  // size="lg"
                                  style={{ boxShadow: " 3px 3px 4px #d29638" }}
                                >
                                  <Modal.Body
                                    style={{
                                      padding: "0px",
                                    }}
                                  >
                                    <div
                                      className="productImgDivModal"
                                      onClick={handleClose}
                                    >
                                      <GrClose />
                                    </div>
                                    <img
                                      // key={product._id}
                                      className="productImgModal"
                                      // src={localStorage.getItem("ProductImage")}
                                      src={`${
                                        process.env.REACT_APP_API_URL_ZIYA
                                      }/${localStorage.getItem(
                                        "ProductImage"
                                      )}`}
                                      alt="img"
                                    />
                                  </Modal.Body>
                                  <Modal.Footer
                                    style={{ justifyContent: "start" }}
                                  >
                                    <p>
                                      For help with sizes, delivery times or
                                      anything else, reach us at{" "}
                                      <span>
                                        <Link
                                          to="https://api.whatsapp.com/send?phone=919321384437"
                                          target="_blanck"
                                          className="whatsappLink"
                                        >
                                          <FaWhatsapp
                                            style={{
                                              backgroundColor: "#09af56",
                                              borderRadius: "15px",
                                              padding: "2px",
                                              color: "#fff",
                                            }}
                                          />
                                        </Link>
                                      </span>{" "}
                                    </p>
                                    <div className="tryAtBtn">
                                      <Link
                                        to="https://api.whatsapp.com/send?phone=919321384437"
                                        className="btn button button-primary animation-horizontal"
                                        target="_blanck"
                                      >
                                        INQUIRY <TbMessageQuestion />
                                      </Link>
                                    </div>
                                  </Modal.Footer>
                                </Modal>
                                {/* <SignUpIndex
                                  modalList={modalList}
                                  setModalList={setModalList}
                                /> */}
                                <LoginModal
                                  modalList={modalList}
                                  setModalList={setModalList}
                                />
                                {isTry && (
                                  <TryonNew2
                                    isTry={isTry}
                                    setIsTry={setIsTry}
                                    tryJewel={tryJewel}
                                    tryProductId={tryProduct}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>

      {showBackToTopButton && (
        <div className="back-top button-show" onClick={scrollToTop}>
          <i className="arrow_carrot-up" />
        </div>
      )}
      <Footer />
      <StoreLocater />
    </React.Fragment>
  );
};

export default ProductDetails;
