import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaCamera, FaRegHeart } from "react-icons/fa";
import { IoIosHeart } from "react-icons/io";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import StoreLocater from "../../components/StoreLocator/StoreLocater";
import TrendingProducts from "../../components/TrendingProducts";
import { listCategory } from "../../functions/ProductDetails/Category";
import { listCategoryProducts } from "../../functions/ProductDetails/ProductCategory";
import {
  AddToWhislist,
  GetWhishlistUser,
  RemoveFromWhislist,
} from "../../functions/Prospect/Prsopect";
import ProductDetailsNewPopup from "../modal/ProductDetialsNewPopup";
import ProcuctDetislPopup from "../modal/ProductDetialspPopUp";
import LoginModal from "../modal/loginModal";
const ProductDetailsNew = () => {
  const params = useParams();
  const ProductID = params.productid;
  const CategoryID = params.selectCategActive;

  const [showPopup, setShowPopup] = useState(false);
  const [modalList, setModalList] = useState(false);

  const tog_list_Camera = () => {
    const userid = localStorage.getItem("RegisterUserID");
    if (!userid) {
      console.log("user found");
      setModalList(true);
    }
  };

  // const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);

  // useEffect(() => {
  //   // Simulate a delay of 5 seconds (5000 milliseconds)
  //   const delay = 7000;
  //   const timeoutId = setTimeout(() => {
  //     setShowProductDetailsModal(true);
  //   }, delay);

  //   // Clear the timeout to prevent the modal from opening if the component unmounts
  //   return () => clearTimeout(timeoutId);
  // }, []);

  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [lisCateg, setLisCateg] = useState([]);
  const [lisCategProd, setLisCategProd] = useState([]);
  const [lisCategActiveItemID, setLisCategActiveItemID] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Amore"); // Initialize it with a default value
  const [selectCategActive, setselectCategActive] = useState(""); // Initialize it with a default value
  const [descr, setDescrip] = useState(""); // Initialize it with a default value

  const [activeCheck, setActiveCheck] = useState(false);
  const [LoadCheck, setLoadCheck] = useState(false);

  useEffect(() => {
    setLoadCheck(true);
    console.log("enter", LoadCheck);
  }, []);

  const { category } = useParams();
  console.log("redirect category", category);

  useEffect(() => {
    // loadCatgeories();
    listCategory().then((res) => {
      setActiveCheck(false);
      setselectCategActive(category);
      console.log("list cate inside", lisCateg);
      const foundCategory = res.data.find((c) => c._id == category);
      console.log("fo", foundCategory);
      setSelectedCategory(foundCategory.Category);
      setDescrip(foundCategory.Description);
      // setLisCategActiveItem(foundCategory.Category);
      setLisCategActiveItemID(foundCategory._id);
    });
  }, [category]);

  // CUSTOMERSIGNUP

  const [UserCheck, setUserCheck] = useState(false);

  useEffect(() => {
    const userid = localStorage.getItem("RegisterUserID");

    GetWhishlistUser(userid)
      .then((res) => {
        console.log("get whishlist", res.data);
        setWishlistList(res.data);
      })
      .catch((err) => {
        console.log("error in getting whishlist");
      });
    // Fetch products based on the weight range
  }, []);

  useEffect(() => {
    if (UserCheck) {
      setModalList(true);
      console.log("mm", modalList);
    }
  }, [UserCheck]);

  useEffect(() => {
    loadCatgeories();
    loadCatgeoryproducts();
    setActiveCheck(true);
  }, []);

  const loadCatgeories = () => {
    listCategory().then((res) => {
      console.log("categ list", res.data[0]);
      setLisCateg(res.data);
    });
  };

  const loadCatgeoryproducts = () => {
    listCategoryProducts().then((res) => {
      console.log("categ list prod", res.data);
      setLisCategProd(res.data);
      console.log("data prod", lisCategProd);
    });
  };

  const handleCategorySelection = (category) => {
    setActiveCheck(false);
    setselectCategActive(category);
    const foundCategory = lisCateg.find((c) => c._id == category);
    setSelectedCategory(foundCategory.Category);
    setDescrip(foundCategory.Description);
    setLisCategActiveItemID(foundCategory._id);
  };

  const [wishlistMessage, setWishlistMessage] = useState("");
  const [removeWishList, setRemoveWishList] = useState("");

  const OnWishListClick = (PID) => {
    console.log("pid", PID);
    let productid = PID;
    localStorage.setItem("ProductID from product details", PID);
    const userid = localStorage.getItem("RegisterUserID");

    if (userid) {
      console.log("user found");
      if (WishlistList.length === 0 || !WishlistList.includes(PID)) {
        console.log("in clicked on Checked whishlist");
        AddToWhislist({ userid, productid })
          .then((res) => {
            console.log("res in addin from product Details", res.data);
            setWishlistList(res.data);
            setWishlistMessage("Product added to wishlist!");

            setTimeout(() => {
              setWishlistMessage("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina adding list", err);
          });
      } else {
        console.log("in clicked on unChecked whishlist");
        RemoveFromWhislist({ userid, productid })
          .then((res) => {
            console.log("res in removeaddin product Details", res.data);
            setWishlistList(res.data);
            setRemoveWishList("Product removed from WishList!");
            setTimeout(() => {
              setRemoveWishList("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina remove list", err);
          });
      }
    } else {
      setModalList(true);
    }
  };

  // HANDLE POPU
  useEffect(() => {
    // Set showPopup to true after a delay (e.g., 5000 milliseconds or 5 seconds)
    const delay = 5000;
    const timeoutId = setTimeout(() => {
      setShowPopup(true);
    }, delay);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // const NextArrow = ({ onClick }) => (
  //   <div className="custom-arrow trend-next-arrow" onClick={onClick}>
  //     <AiOutlineRight />
  //   </div>
  // );

  // const PrevArrow = ({ onClick }) => (
  //   <div className="custom-arrow trend-prev-arrow" onClick={onClick}>
  //     <AiOutlineLeft />
  //   </div>
  // );

  const [WishlistList, setWishlistList] = useState([]);

  useEffect(() => {
    const userid = localStorage.getItem("RegisterUserID");

    GetWhishlistUser(userid)
      .then((res) => {
        console.log("get whishlist", res.data);
        setWishlistList(res.data);
      })
      .catch((err) => {
        console.log("error in getting whishlist");
      });
    // Fetch products based on the weight range
  }, []);

  const [categPro, setCategPro] = useState([]);
  const [categ, setCateg] = useState([]);

  useEffect(() => {
    loadCategoryProducts();
    loadCategory();
  }, []);

  const loadCategoryProducts = () => {
    listCategoryProducts().then((res) => {
      console.log("RES IN CATEG PROD", res.data);
      setCategPro(res.data);
    });
  };
  const loadCategory = () => {
    listCategory().then((res) => {
      console.log("RES IN CATEG", res.data);
      setCateg(res.data);
    });
  };

  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4, // Display 4 products at a time
  //   slidesToScroll: 1,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 769,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 525,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 4,
  //       },
  //     },
  //   ],
  // };
  // const productData = [
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  // ];
  return (
    <React.Fragment>
      <Header />
      <ProcuctDetislPopup />
      <div id="site-main" className="site-main">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div
              id="title"
              className="page-title page-title-dark title-product-details"
            >
              <div className="section-container text-start">
                {categ
                  .filter((c) => c._id === CategoryID)
                  .map((cp) => (
                    <div className="content-title-heading" key={cp._id}>
                      <h1 className="text-title-heading">{cp.Category}</h1>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        {categPro
          .filter((c) => c._id === ProductID)
          .map((product) => (
            <Row>
              <Col className="col-lg-3 col-md-12 col-12 sidebar left-sidebar blog-details-sidebar">
                <div className="block block-post-tags">
                  <div className="block-title">
                    <h2 className="categroyTitle">Categories</h2>
                  </div>
                  <div className="block-content">
                    <div className="post-tags-list">
                      <ul className="categoryUl">
                        {lisCateg.map((l, index) =>
                          l.isActive && !l.IsTopProducts ? (
                            <React.Fragment key={l._id}>
                              <li
                                value={l._id}
                                onClick={() => handleCategorySelection(l._id)}
                                className={
                                  l._id === lisCategActiveItemID
                                    ? "activeCategory"
                                    : ""
                                }
                              >
                                <button
                                  className="btn btn-lg product-button"
                                  style={{
                                    background: "none",
                                    border: "none",
                                    padding: "7px",
                                    font: "inherit",
                                    width: "100%",
                                    textAlign: "start",
                                    textDecoration: "none",
                                    color:
                                      l._id === lisCategActiveItemID && "white",
                                    cursor: "pointer",
                                  }}
                                >
                                  {l.Category}
                                </button>
                              </li>
                            </React.Fragment>
                          ) : null
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="4">
                <Card className="productCard">
                  <img
                    src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`}
                    alt={product.ProductName}
                  />
                </Card>
              </Col>
              <Col lg="5" style={{ paddingLeft: "80px" }}>
                <div className="wishlistDiv">
                  <div className="tryAtBtn" onClick={tog_list_Camera}>
                    <Link
                      to="/product-details"
                      className=" animation-horizontal backBtn "
                    >
                      <IoArrowBackCircleSharp /> <spam>Back</spam>
                    </Link>
                  </div>
                  <Link
                    to="#"
                    style={{
                      textAlign: "end",
                    }}
                    onClick={() => OnWishListClick(product._id)}
                  >
                    {WishlistList.includes(product._id) ? (
                      <IoIosHeart
                        style={{
                          color: "#e41717cf",
                          fontSize: "24px",
                        }}
                      />
                    ) : (
                      <FaRegHeart
                        style={{
                          fontSize: "22px",
                        }}
                      />
                    )}
                    {/* <FaRegHeart /> */}
                  </Link>
                  {wishlistMessage && (
                    <div className="wishlist-message-container">
                      <div className="wishlist-message">
                        <h4
                          style={{
                            color: "white",
                          }}
                        >
                          {wishlistMessage}
                        </h4>
                      </div>
                    </div>
                  )}
                  {removeWishList && (
                    <div className="wishlist-remove-container">
                      <div className="wishlist-remove-message">
                        <h4
                          style={{
                            color: "white",
                          }}
                        >
                          {removeWishList}
                        </h4>
                      </div>
                    </div>
                  )}
                </div>

                <Row>
                  {" "}
                  <Col lg="12">
                    <div key={product._id}>
                      {product.MetalDetails.map((metal, index) => (
                        <Card
                          className="productCardSection"
                          style={{ marginTop: "15px" }}
                        >
                          <div key={index} className="metalDetailContainer">
                            <div>
                              <h3 className="metalName">{metal.MetalName}</h3>
                              <p className="metalWeight">
                                <b style={{ color: "#3c2147" }}>
                                  {" "}
                                  {metal.MetalWeight} g
                                </b>
                              </p>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </Col>
                  {/* ))} */}
                  <Col lg={6}>
                    <div className="tryAtBtn" onClick={tog_list_Camera}>
                      <Link
                        to="#"
                        className="button button-primary animation-horizontal tryAtHome"
                      >
                        TRY ON
                        <FaCamera />
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}></Col>
                </Row>
                <LoginModal modalList={modalList} setModalList={setModalList} />
              </Col>
            </Row>
          ))}
      </Container>

      {showPopup && (
        <ProductDetailsNewPopup isOpen={true} onClose={handleClosePopup} />
      )}

      <div className="section section-padding background-img bg-img-3 p-t-60 p-b-70 m-b-70">
        <TrendingProducts />
      </div>
      <Footer />
      <StoreLocater />
    </React.Fragment>
  );
};

export default ProductDetailsNew;
