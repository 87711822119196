import React from "react";
import { Link } from "react-router-dom";
import WhatImg from "./../assets/media/whatsapp.png";

const WhatsAppIconComan = () => {
  return (
    <React.Fragment>
      <div className="mainDivWharsappImg">
        <div>
          <Link
            to="https://api.whatsapp.com/send/?phone=%2B919321384437&text=Hi"
            target="_blanck"
          >
            <img className="whatsappIcon" src={WhatImg} alt="icon" />
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WhatsAppIconComan;
